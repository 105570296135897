.Filter {
    text-align: center;
    color: white
  }
  
  .btn {
    border: none;
    background-color: inherit;
    /* padding: 14px 28px; */
    font-size: 16px;
    cursor: pointer;
    display: inline-block;
    color: white;
  }
  
  /* On mouse-over */
  .btn:hover {
    background: #473e3a;
  }
  
  
  .Filter-header {
    background-color: #4f4541;
    /*min-height: 100vh;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(4px + 2vmin);
    color: black;
  }
  
  .Filter-link {
    color: #61dafb;
  }
  .modal-filter {
    font-size: 13px;
  }
  .modal-filter > .header {
    width: 100%;
    border-bottom: 2px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
    color: black
  }
  .modal-filter > .content {
    width: 100%;
    padding: 10px 5px;
    color: black;
    text-align: center  
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto;
    text-align: center;
    grid-gap: 1px;
    padding: 5px;
    /* white-space: nowrap; */
  }

  .modal-filter > .actions {  
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
    color: black
  }
  .modal-filter > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
    color: black
  }

  .season-filters{
    border-top: 2px solid gray;
    /* border-top: 100%; */
    /* padding: 5px; */
  }

  .Filter-header-header{
    /* border-bottom: 1px solid gray; */
    /* border-bottom-width: 80%; */
    font-size: calc(4px + 1.5vmin);
    padding:5px;
  }