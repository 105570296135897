body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #785078;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.modal-app {
  font-size: 12px;
}
.modal-app > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
  color: black
}
.modal-app > .content {
  width: 100%;
  padding: 10px 5px;
  color: black

}
.modal-app > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
  color: black
}
.modal-app > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
  color: black
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Filter {
    text-align: center;
    color: white
  }
  
  .btn {
    border: none;
    background-color: inherit;
    /* padding: 14px 28px; */
    font-size: 16px;
    cursor: pointer;
    display: inline-block;
    color: white;
  }
  
  /* On mouse-over */
  .btn:hover {
    background: #473e3a;
  }
  
  
  .Filter-header {
    background-color: #4f4541;
    /*min-height: 100vh;*/
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(4px + 2vmin);
    color: black;
  }
  
  .Filter-link {
    color: #61dafb;
  }
  .modal-filter {
    font-size: 13px;
  }
  .modal-filter > .header {
    width: 100%;
    border-bottom: 2px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
    color: black
  }
  .modal-filter > .content {
    width: 100%;
    padding: 10px 5px;
    color: black;
    text-align: center  
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto;
    text-align: center;
    grid-gap: 1px;
    padding: 5px;
    /* white-space: nowrap; */
  }

  .modal-filter > .actions {  
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
    color: black
  }
  .modal-filter > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
    color: black
  }

  .season-filters{
    border-top: 2px solid gray;
    /* border-top: 100%; */
    /* padding: 5px; */
  }

  .Filter-header-header{
    /* border-bottom: 1px solid gray; */
    /* border-bottom-width: 80%; */
    font-size: calc(4px + 1.5vmin);
    padding:5px;
  }
.right{
    /* display:flex; */
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}


